import { sendEvent } from '../sendEvent';
import {
  TrackingEvent,
  WebEventCategory,
  SubscriptionEventTracking,
  SubscriptionEvent,
} from '@Core/tracking/types';

export const useSubscriptionTracking =
  () => (action: string, source: string, label: SubscriptionEvent) =>
    sendEvent<SubscriptionEventTracking>({
      event: TrackingEvent.webEvent,
      category: WebEventCategory.subscription,
      label,
      action,
      source,
    });
